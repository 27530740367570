import React from "react";
import Warning from "@material-ui/icons/Warning";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import moment from "moment";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import Button from "../../../components/CustomButtons/Button";
import secaoStyle from "../../../assets/css/layout/secaoStyle";
import { withStyles, Checkbox, Paper } from "@material-ui/core";
import Table from "../../../components/Table/Table";
import {
  post,
  imprimirPagamento,
  postSimular,
  consultarDivida,
} from "../../../util/pagamentoDeDivida";
import {
  getReceitas,
  getDividaEmAbertoComFiltros,
} from "../../../util/dividas";
import { getField } from "../../../util/refinanciamento";
import {
  showNotification,
  mapErrors,
  showWaiting,
  hideWaiting,
} from "../../../util/Constants";
import LoadingContent from "../../../components/LoadingContent";
import { simpleSearch as searchContribuinte } from "../../../util/contribuinte";
import AsyncSelect from "../../../components/AsyncSelect";
import SearchFields from "../../../components/SearchFields";
import housecall from "housecall";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { contribuinteChanged } from "../../../actions";
import numeral from "../../../util/numeral";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DecimalFormatCustom from "../../../components/DecimalFormatCustom";
import { me } from "../../../util/authApi";

class PagamentoForm extends React.Component {
  constructor(props) {
    super(props);
    let contribuinte = props.contribuinte;
    let contribuinteState = { contribuintes: new Array() };
    let search = {};
    let dividas = new Array();
    if (contribuinte) {
      search = {
        codigo_de_origem: contribuinte.origem,
        receita_id: contribuinte.receita,
        competencia: contribuinte.competencia,
      };
      contribuinteState = {
        contribuintes: [props.contribuinte],
        contribuinte_id: {
          value: props.contribuinte.id,
          label: `${props.contribuinte.id} - ${props.contribuinte
            .nome_razao_social || ""} - ${props.contribuinte.cpf_cnpj || ""}`,
        },
      };
    }
    this.state = {
      observacao: "",
      desconto: 0,
      errors: {},
      data_inicio: new Date(),
      data_fim: new Date(),
      valor_minimo_fisica: "",
      valor_minimo_juridica: "",
      data_de_vencimento: new Date(),
      lei: "",
      abragencias: [],
      id_abragencias: [],
      id_refis: "",
      refis: [],
      dividas: dividas,
      usuario_id: null,
      id_divida: 0,
      quantidade_de_parcelas: "",
      historico: "",
      editingItem: 0,
      isLoadingDialog: false,
      modal: false,
      canShow: false,
      parcelasSimulacao: [],
      showWarningModal: false,
      showField: true,
      ...contribuinteState,
      selectAll: false,
      search: { ...search },
      receitas: [],
      nao_tem_divida: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.simulacao = this.simulacao.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseWarning = this.handleCloseWarning.bind(this);
    this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.getDividaContribuinte = this.getDividaContribuinte.bind(this);
    this.imprimirCarneRef = this.imprimirCarneRef.bind(this);
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
    this.consultar = this.consultar.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.getReceitas = this.getReceitas.bind(this);
    this.getDividas = this.getDividas.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.cleanAdvancedSearch = this.cleanAdvancedSearch.bind(this);
  }
  getReceitas() {
    const response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Erro ao carregar as receitas",
          "danger"
        );
      } else {
        this.setState({
          receitas: data,
        });
      }
      hideWaiting();
    };
    getReceitas(response);
  }

  consultar() {
    this.setState(
      {
        showField: false,
      },
      () => {
        let id = this.state.dividas
          .map((item) => {
            return item.id;
          })
          .join();
        showWaiting();
        consultarDivida(id, (error, data) => {
          hideWaiting();
          if (error) {
            showNotification(
              "top",
              "center",
              "Ocorreram erros! Tente novamente",
              "danger"
            );
          } else {
            this.setState(
              {
                valor_primeira_parcela: parseFloat(data.valor_primeira_parcela),
              },
              () => {
                this.setState({
                  showField: true,
                });
              }
            );
          }
        });
      }
    );
  }

  handleClose() {
    this.setState({ modal: false });
  }
  handleCloseWarning() {
    this.setState({ showWarningModal: false });
  }

  simulacao(event) {
    event.preventDefault();
    showWaiting();
    let filter = this.state.dividas.filter((item) => {
      return item.checkbox === true;
    });
    let dividas = filter.map((item) => {
      return item.id;
    });

    let params = {
      dividas: dividas,
      contribuinte_id: this.state.contribuinte_id.value,
      data_de_vencimento: moment(this.state.data_de_vencimento).format(
        "YYYY-MM-DD"
      ),
      quantidade_de_parcelas: this.state.quantidade_de_parcelas,
      desconto: this.state.desconto,
    };

    let response = (error, data) => {
      hideWaiting();
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );

        this.setState({
          errors: mapErrors(error),
        });
      } else {
        this.setState({
          modal: true,
          parcelasSimulacao: data,
        });
      }
    };
    postSimular(params, response);
  }

  componentWillUnmount() {
    this.props.contribuinteChanged(undefined);
  }

  componentDidMount() {
    me((error, data) => {
      if (error) {
        return;
      }
      this.setState({ usuario_id: data.id });
    });
    getField((error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
      } else {
        if (data.refis.length > 0) {
          this.setState({
            showWarningModal: true,
            canShow: true,
          });
        } else {
          this.setState({
            canShow: true,
          });
        }
      }
    });
    this.getReceitas();
    if (this.state.contribuinte_id) {
      this.getDividas();
    }
  }
  getDividas() {
    showWaiting();
    let arrayParams = new Array();
    Object.entries({ ...this.state.search }).forEach(([key, value]) => {
      arrayParams.push(`${key}=${value}`);
    });
    const params = `?${arrayParams.join("&")}`;
    const response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
      } else {
        this.setState({
          dividas: data.map((item) => {
            return { ...item, checkbox: true };
          }),
          selectAll: true,
          nao_tem_divida: data.length === 0,
        });
      }
      hideWaiting();
    };
    if (!this.state.contribuinte_id) {
      showNotification(
        "top",
        "center",
        "Contribuinte é obrigatório",
        "warning"
      );
      hideWaiting();
    } else {
      getDividaEmAbertoComFiltros(
        this.state.contribuinte_id.value,
        params,
        response
      );
    }
  }
  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          );
          callback([]);
        } else {
          let contribuintes = data.data.map((contribuinte) => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`,
            };
          });
          this.setState({
            contribuintes: data.data,
          });
          callback(contribuintes);
        }
      }
    );
  }

  toggleLoadingDialog() {
    this.setState((state) => ({
      isLoadingDialog: !state.isLoadingDialog,
    }));
  }
  handleChangeCheckbox(event) {
    const name = parseInt(event.target.name);
    const checked = event.target.checked;
    let newDividas = this.state.dividas;
    newDividas.map((item, index, dividas) => {
      if (index === name) {
        item.checkbox = checked;
      }
      dividas[index] = { ...item };
    });
    this.setState({
      dividas: newDividas,
    });
  }
  checkAll(event) {
    let checked = true;
    if (event) {
      checked = event.target.checked;
    }
    let newDividas = this.state.dividas;
    newDividas.map((item, index, dividas) => {
      item.checkbox = checked;
      dividas[index] = { ...item };
    });
    this.setState({
      dividas: newDividas,
      selectAll: checked,
    });
  }
  async handleChange(event) {
    const name = event.target.name;
    const errors = this.state.errors;
    await this.setState({
      [name]: event.target.value,
      errors: errors,
    });
  }
  async handleSearchChange(event) {
    const name = event.target.name.replace("search_", "");
    if (event.target.value) {
      await this.setState({
        search: { ...this.state.search, [name]: event.target.value },
      });
    } else {
      delete this.state.search[name];
      await this.setState({
        search: { ...this.state.search },
      });
    }
  }
  async cleanAdvancedSearch() {
    await this.setState({
      search: {},
    });
    this.advancedSearch();
  }
  getDividaContribuinte(id) {
    return this.state.contribuintes.filter((item) => {
      return id === item.id;
    })[0];
  }

  handleSave(event) {
    event.preventDefault();
    if (this.state.contribuinte_id) {
      showWaiting();
      let filter = this.state.dividas.filter((item) => {
        return item.checkbox === true;
      });
      let dividas = filter.map((item) => {
        return item.id;
      });

      let params = {
        dividas: dividas,
        contribuinte_id: this.state.contribuinte_id.value,
        data_de_vencimento: moment(this.state.data_de_vencimento).format(
          "YYYY-MM-DD"
        ),
        quantidade_de_parcelas: this.state.quantidade_de_parcelas,
        desconto: this.state.desconto,
        observacao: this.state.observacao,
      };
      let response = (error, data) => {
        if (error) {
          hideWaiting();
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique o formulário e tente novamente",
            "danger"
          );

          this.setState({
            errors: mapErrors(error),
          });
        } else {
          showNotification(
            "top",
            "center",
            `Pagamento de dívida ${
              this.props.match.params.id ? "editado" : "cadastrado"
            } com sucesso`,
            "success"
          );
          this.imprimirCarneRef(data.id);
        }
      };
      post(params, response);
    }
  }

  toggleLoading() {
    this.setState((state) => ({
      isLoading: !state.isLoading,
    }));
  }

  imprimirCarneRef(pagamentoId) {
    let response = (error, data) => {
      hideWaiting();
      if (error) {
        const reader = new FileReader();
        reader.addEventListener("loadend", (e) => {
          const text = e.srcElement.result;
          try {
            let error = JSON.parse(text);

            let status = error.status;
            if (status === "pago") {
              showNotification(
                "top",
                "center",
                `Ocorreram erros! ${error.message}!`,
                "danger"
              );
            }
          } catch (e) {
            showNotification(
              "top",
              "center",
              `Ocorreram erros! ${error.message}`,
              "danger"
            );
          }
        });

        reader.readAsText(error.response.data);
      } else {
        var url = URL.createObjectURL(data);
        let tempLink = document.createElement("a");
        tempLink.setAttribute("target", "_blank");
        tempLink.setAttribute("href", `${url}`);
        tempLink.setAttribute("style", "display:none");
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        this.props.history.push("/divida/cadastro");
      }
    };
    imprimirPagamento(pagamentoId, response);
  }

  render() {
    const { classes } = this.props;
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" },
    ];
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <div>
            <LoadingContent isLoading={this.state.canShow === false}>
              <Card>
                <form onSubmit={this.handleSave}>
                  <CardHeader color="primary">
                    <Grid container direction="row">
                      <Grid item lg={10} xs={12}>
                        <h2 className={classes.cardTitleWhite}>
                          Pagamento de dívida (Parcelamento)
                        </h2>
                      </Grid>
                    </Grid>
                  </CardHeader>
                  <CardBody>
                    <Grid container direction="row" spacing={16}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SearchFields
                          name="filterField"
                          value={this.state.filterField || ""}
                          onChangeFields={this.handleChange}
                          fields={fields}
                        />
                        <Typography color="textSecondary" variant="caption">
                          Pesquise o Contribuinte
                        </Typography>
                        <AsyncSelect
                          className={`form-control form-control-alternative form-select Select-menu-outer ${
                            this.props.error ? "has-danger" : ""
                          }`}
                          value={this.state.contribuinte_id}
                          onChange={this.handleChange}
                          loadOptions={this.loadOptions}
                          error={this.state.errors.contribuinte_id}
                          helperText={this.state.errors.contribuinte_id}
                          name="contribuinte_id"
                          placeholder="Contribuinte"
                          message="Pesquise o contribuinte"
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={16}>
                      <Grid item xs={12} sm={6} md={2} lg={1}>
                        <TextField
                          id="outlined-select-currency"
                          label="Competência"
                          fullWidth
                          name="search_competencia"
                          value={this.state.search.competencia || ""}
                          onChange={this.handleSearchChange}
                          margin="normal"
                          variant="outlined"
                          defaultValue=""
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-select-currency"
                          label="Origem"
                          fullWidth
                          name="search_codigo_de_origem"
                          value={this.state.search.codigo_de_origem || ""}
                          onChange={this.handleSearchChange}
                          margin="normal"
                          variant="outlined"
                          defaultValue=""
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          name="search_receita_id"
                          label="Receita"
                          fullWidth
                          value={this.state.search.receita_id || ""}
                          onChange={this.handleSearchChange}
                          margin="normal"
                          variant="outlined"
                        >
                          {this.state.receitas.map((option, key) => (
                            <MenuItem key={key} value={option.id}>
                              {`${option.id.toString().padStart(3, "0")} ${
                                option.nome
                              }`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={16}
                      justify={"flex-end"}
                    >
                      <Grid item xs={12} sm={4} md={3} lg={2}>
                        <Button
                          block
                          color="danger"
                          fullWidth
                          onClick={this.cleanAdvancedSearch}
                        >
                          <DeleteIcon /> Limpar Busca
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={2}>
                        <Button
                          block
                          color="primary"
                          fullWidth
                          onClick={this.getDividas}
                        >
                          <SearchIcon /> Buscar
                        </Button>
                      </Grid>
                    </Grid>
                    {this.state.dividas.length > 0 ? (
                      <div>
                        <Typography color="textSecondary" variant="caption">
                          Selecione as dívidas que serão incluídas no
                          pagamento
                        </Typography>
                        <div>Selecione todos</div>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={1}
                          lg={1}
                          alignItems="center"
                          style={{ display: "inline-block" }}
                          justify="center"
                        >
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                            checkedIcon={<CheckBoxIcon fontSize="large" />}
                            onChange={this.checkAll}
                            checked={this.state.selectAll}
                            name={"Selecionar todos"}
                          />
                        </Grid>
                        {this.state.dividas.map((item, key) => {
                          return (
                            <div>
                              <Card>
                                <Grid
                                  style={{ marginBottom: "15px" }}
                                  container
                                  direction="row"
                                  justify="flex-center"
                                  alignItems="center"
                                  spacing={8}
                                  key={key}
                                >
                                  <Grid
                                    className={classes.flexInherit}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={1}
                                    lg={1}
                                    alignItems="center"
                                    justify="center"
                                  >
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize="large" />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize="large" />
                                      }
                                      onChange={this.handleChangeCheckbox}
                                      value={item.checkbox}
                                      checked={item.checkbox}
                                      name={key}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <strong>Receita: </strong>
                                    <span>
                                      {item.receita_id} - {item.receita.nome}{" "}
                                    </span>
                                    <br />
                                    <strong>Competência: </strong>
                                    <span>{item.competencia} </span>
                                    <br />
                                    <strong>Código de origem: </strong>
                                    <span>{item.codigo_de_origem} </span>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <strong>Valor original: </strong>
                                    <span>
                                      {"R$" +
                                        numeral(parseFloat(item.valor))
                                          .format("0.0,00")
                                          .slice(0, -2)}
                                    </span>
                                    <br />
                                    <strong>Valor pago: </strong>
                                    <span>
                                      {"R$" +
                                        numeral(parseFloat(item.valor_pago))
                                          .format("0.0,00")
                                          .slice(0, -2)}
                                    </span>
                                    <br />
                                    <strong>Saldo devedor: </strong>
                                    <span>
                                      {"R$" +
                                        numeral(parseFloat(item.saldo_devedor))
                                          .format("0.0,00")
                                          .slice(0, -2)}
                                    </span>
                                    <br />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <strong>Correção monetária: </strong>
                                    <span>
                                      {"R$" +
                                        numeral(
                                          parseFloat(item.correcao_monetaria)
                                        )
                                          .format("0.0,00")
                                          .slice(0, -2)}
                                    </span>
                                    <br />
                                    <strong>Multa: </strong>
                                    <span>
                                      {"R$" +
                                        numeral(parseFloat(item.multa))
                                          .format("0.0,00")
                                          .slice(0, -2)}
                                    </span>
                                    <br />
                                    <strong>Juros: </strong>
                                    <span>
                                      {"R$" +
                                        numeral(parseFloat(item.juros))
                                          .format("0.0,00")
                                          .slice(0, -2)}
                                    </span>
                                    <br />
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <h3>
                                      Total a pagar:{" "}
                                      {"R$" +
                                        numeral(
                                          parseFloat(item.saldo_devedor) +
                                            parseFloat(
                                              item.correcao_monetaria
                                            ) +
                                            parseFloat(item.multa) +
                                            parseFloat(item.juros)
                                        )
                                          .format("0.0,00")
                                          .slice(0, -2)}
                                    </h3>
                                  </Grid>
                                </Grid>
                              </Card>
                              {this.state.errors && (
                                <Typography variant="caption" color="error">
                                  {this.state.errors[`dividas.${key}`]}
                                </Typography>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      this.state.contribuinte_id && (
                        <Grid
                          className={classes.legend}
                          container
                          direction="row"
                        >
                          <Warning
                            className={classes.dot + " " + classes.dotDanger}
                          />
                          Este contribuinte não possui dívida ativa em aberto
                        </Grid>
                      )
                    )}
                    {this.state.dividas.length > 0 && (
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            type="number"
                            variant="outlined"
                            margin="normal"
                            name="quantidade_de_parcelas"
                            label="Quantidade de parcelas"
                            className={classes.datePicker}
                            helperText={
                              this.state.errors.quantidade_de_parcelas
                            }
                            value={this.state.qtd_parcelas}
                            error={this.state.errors.quantidade_de_parcelas}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="date"
                            type="date"
                            variant="outlined"
                            margin="normal"
                            name="data_de_vencimento"
                            label="Data do primeiro Vencimento"
                            className={classes.datePicker}
                            helperText={this.state.errors.data_de_vencimento}
                            value={moment(this.state.data_de_vencimento).format(
                              "YYYY-MM-DD"
                            )}
                            error={this.state.errors.data_de_vencimento}
                            defaultValue={moment(
                              this.state.data_de_vencimento
                            ).format("YYYY-MM-DD")}
                            onChange={this.handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            name="desconto"
                            label="Desconto"
                            fullWidth
                            error={this.state.errors.desconto}
                            helperText={this.state.errors.desconto}
                            value={parseFloat(this.state.desconto)}
                            defaultValue={parseFloat(this.state.desconto)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom,
                            }}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="outlined-select-currency"
                            name="observacao"
                            label="Observação"
                            fullWidth
                            error={this.state.errors.observacao}
                            helperText={this.state.errors.observacao}
                            value={this.state.observacao}
                            defaultValue={this.state.observacao}
                            multiline
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </CardBody>
                  <CardFooter>
                    <Grid container justify="center" spacing={8}>
                      <Grid item lg={2}>
                        <Button
                          block
                          color="info"
                          round
                          onClick={this.handleSave}
                        >
                          Confirmar
                        </Button>
                      </Grid>
                      <Grid item lg={2}>
                        <Button
                          block
                          color="default"
                          round
                          onClick={this.simulacao}
                          disabled={!(this.state.dividas.length > 0)}
                        >
                          Simular
                        </Button>
                      </Grid>
                    </Grid>
                  </CardFooter>
                </form>
              </Card>
            </LoadingContent>
          </div>
        </LoadingContent>
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "901" }}
        >
          <DialogTitle id="form-dialog-title">
            Simulação de Parcelas
          </DialogTitle>
          <DialogContent>
            <Paper className={classes.tableWrapper}>
              <Table
                tableHeaderColor="primary"
                fullWidth
                actions={[]}
                tableHead={[
                  "Nº Parcela",
                  "Valor",
                  // "Juros", "Multa",
                  "Vencimento",
                ]}
                tableData={this.state.parcelasSimulacao.map((item, key) => {
                  return [
                    item.numero,
                    `R$ ${numeral(parseFloat(item.valor))
                      .format("0.0,00")
                      .slice(0, -2)}`,
                    // `R$ ${numeral(parseFloat(item.juros)).format(
                    //   "0.0,00"
                    // ).slice(0, -2)}`,
                    // `R$ ${numeral(parseFloat(item.multa)).format(
                    //   "0.0,00"
                    // ).slice(0, -2)}`,
                    moment(item.vencimento).format("DD/MM/YYYY"),
                    key,
                  ];
                })}
              />
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showWarningModal}
          onClose={this.handleCloseWarning}
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "901" }}
        >
          <DialogContent>
            <h2 className={classes.cardTitleWhite + " " + classes.textWarning}>
              AVISO: Existe um REFIS vigente, é recomendado que o pagamento
              seja feito usando o modulo de REFIS.
            </h2>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseWarning} color="danger">
              Continuar com o pagamento
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ contribuinteChanged }, dispatch);
}

function mapStateToProps(state) {
  return {
    contribuinte: state.contribuinte.contribuinte,
  };
}

PagamentoForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PagamentoForm);
export default withStyles(secaoStyle)(PagamentoForm);
